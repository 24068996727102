@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

body {
  font-family: 'IBM Plex Mono', monospace;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill-opacity=".1"><rect x="50" width="50" height="50"/><rect y="50" width="50" height="50"/></svg>');
  background-attachment: fixed;
  background-color: #4ec0ca;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
 
}

.title {
  text-align: center;
  font-family: 'Press Start 2P', cursive;
  color: #ffdf00;
  margin-bottom: 20px;
  text-shadow: 2px 2px 0px #3a474d;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.wallet-section {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;

  .wallet-button {
    background-color: #7158e2 !important;
    color: #ffffff !important;
    font-family: 'Press Start 2P', cursive;
    font-size: 0.9em;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.2s, transform 0.1s;

    &:hover {
      background-color: #5e47c0 !important;
      transform: scale(1.05);
    }

    &:active {
      background-color: #4b38a3 !important;
      transform: scale(0.98);
    }
  }

 


}

.container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
  width: 90%;
  max-width: 1200px;
}

.score-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2c3e50;
  padding: 15px;
  border-radius: 10px;
  width: 180px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  font-family: 'Press Start 2P', cursive;
  color: #ffffff;
  text-align: center;

  .score, .best {
    font-size: 1em;
    margin: 8px 0;
    color: #ffdf00;
  }

  .label {
    font-size: 0.9em;
    color: #ecf0f1;
  }

  .start-game-button {
    margin-top: 15px;
    padding: 10px 15px;
    background-color: #e67e22;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 0.9em;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.1s;

    &:hover {
      background-color: #d35400;
      transform: scale(1.05);
    }

    &:active {
      background-color: #c0392b;
      transform: scale(0.98);
    }
  }
}

.game {
  display: flex;
  justify-content: center;
  align-items: center;

  #canvas {
    border: 3px solid #34495e;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
}

.leaderboard {
  color: #FFFFFF;
  padding: 15px;
  background-color: #2c3e50;
  border-radius: 10px;
  width: 250px;
  font-family: 'Press Start 2P', cursive;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  border: 4px solid #ff9f43; /* Thick border for retro aesthetic */

  h2 {
    color: #ffdf00;
    margin-bottom: 10px;
    font-size: 1.2em;
    text-transform: uppercase;
    text-shadow: 2px 2px 0px #3a474d; /* Retro-style shadow */
  }

  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.85em;
  }

  th, td {
    padding: 10px;
    text-align: center;
    color: #ffffff;
    border: 2px solid #34495e; /* Thick borders for retro look */
    font-size: 0.85em;
  }

  th {
    background-color: #ff9f43;
    color: #2c3e50;
    font-weight: bold;
    text-transform: uppercase;
  }

  td {
    background-color: #34495e;
  }

  tr:nth-child(even) td {
    background-color: #3a4a5b; /* Slightly darker color for alternating rows */
  }

  tr:hover td {
    background-color: #2b3a47; /* Highlight row on hover */
  }
}

/* style.css */

/* General card styling for responsiveness */
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  flex: 1 1 300px;
  margin: 10px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.wallet-input, .referral-link-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  font-size: 14px;
}

.generate-link, .copy-link {
  width: 100%;
  padding: 10px;
  background-color: #ffcc00;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
  margin-top: 5px;
}

.generate-link:hover, .copy-link:hover {
  background-color: #e6b800;
}

.referral-info {
  text-align: center;
  margin-top: 10px;
}


/* Modal Animation */
@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.9);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

/* Leaderboard Styles */
.leaderboard {
  color: #FFFFFF;
  padding: 15px;
  background-color: #2c3e50;
  border-radius: 10px;
  width: 100%;
  font-family: 'Press Start 2P', cursive;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  text-align: center;
  border: 4px solid #ff9f43; /* Retro thick border */
  max-height: 300px; /* Restrict max height for mobile */
  overflow-y: auto; /* Scrolls when content is too long */
}

.leaderboard h2 {
  color: #ffdf00;
  margin-bottom: 10px;
  font-size: 1.2em;
  text-transform: uppercase;
  text-shadow: 2px 2px 0px #3a474d; /* Retro-style shadow */
}

/* Leaderboard Table */
.leaderboard table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.85em;
}

.leaderboard th, .leaderboard td {
  padding: 8px;
  text-align: center;
  color: #ffffff;
  border: 2px solid #34495e; /* Thick borders for retro look */
  font-size: 0.85em;
}

.leaderboard th {
  background-color: #ff9f43;
  color: #2c3e50;
  font-weight: bold;
  text-transform: uppercase;
}

.leaderboard td {
  background-color: #34495e;
}

.leaderboard tr:nth-child(even) td {
  background-color: #3a4a5b; /* Alternate row color */
}

.leaderboard tr:hover td {
  background-color: #2b3a47; /* Highlight on hover */
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .leaderboard {
    padding: 10px;
    max-height: 200px; /* Smaller max height on mobile */
  }

  .leaderboard h2 {
    font-size: 1em;
  }

  .leaderboard th, .leaderboard td {
    padding: 5px;
    font-size: 0.75em; /* Smaller font for mobile */
  }
}
