@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body {
    font-family: 'IBM Plex Mono', monospace;
    background-color: #4ec0ca;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill-opacity=".1"><rect x="50" width="50" height="50"/><rect y="50" width="50" height="50"/></svg>');
    background-attachment: fixed;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
}

.title {
    font-size: 50px;
    font-family: 'Press Start 2P', cursive;
    color: #ffdf00;
    text-align: center;
    margin-top: 2rem;
    text-shadow: 3px 3px 0px #3a474d;
}

p {
    font-family: 'Press Start 2P', cursive;    
}

.card-container {
    display: flex;
    justify-content: center;
    gap: 16px;
    flex-wrap: wrap;
    margin: 2rem 0;
}

.card {
    background: #ffffff;
    box-shadow: 4px 4px 0px 0px #000000;
    padding: 16px;
    text-align: center;
    width: 300px;
    margin-bottom: 1rem;
    position: relative;
    font-family: 'Press Start 2P', cursive; 
}

.card::before, .card::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, transparent 2px, #ffffff 2px), 
                linear-gradient(transparent 2px, #ffffff 2px),
                linear-gradient(90deg, transparent 2px, #ffffff 2px), 
                linear-gradient(transparent 2px, #ffffff 2px);
    background-repeat: repeat-x, repeat-y;
    background-size: 4px 4px, 4px 4px, 4px 4px, 4px 4px;
    pointer-events: none;
}

.card::after {
    mix-blend-mode: difference;
}

.card button {
    background-color: #f1c40f;
    color: #2c3e50;
    cursor: pointer;
    font-family: 'Press Start 2P', cursive;
    font-size: 1rem;
    padding: 10px 20px;
    text-transform: uppercase;
    transition: background-color 0.3s;
    border: none;
    box-shadow: 4px 4px 0px 0px #000000;
}

.card button:hover {
    background-color: #e67e22;
}

.section {
    background: rgba(255, 255, 255, 0.1);
    margin: 2rem 0;
    padding: 1rem;
    border: 3px solid #000000;
    position: relative;
    text-align: center;
}

.section-title {
    font-size: 1.2rem;
    color: #ffdf00;
    font-family: 'Press Start 2P', cursive;
    position: relative;
    padding: 0.5rem 1rem;
    display: inline-block;
    top: -1.4rem;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 3px 3px 0px #000000;
}

.section-description {
    color: #fff;
    font-size: 0.8rem;
    line-height: 1.5;
    padding: 1rem;
    text-align: left;
    box-shadow: none;
}

.material-icons {
    font-size: 4.5rem;
    display: block;
    margin: 0 auto 1rem;
}

.about-list,
.steps {
    text-align: center;
    padding: 0;
}

.about-banner {
    max-width: 100%;
    height: auto;
    image-rendering: pixelated;
    border: 3px solid #000000;
    box-shadow: 4px 4px 0px 0px #000000;
    margin: 0 auto;
}

.about-list li,
.step {
    background: #000000cf;
    color: #ffffff;
    box-shadow: 3px 3px 0 #000000;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    display: inline-block;
}

.step-icon {
    width: 64px;
    height: auto;
    image-rendering: pixelated;
    margin-bottom: 0.5rem;
}

img {
    image-rendering: pixelated;
}

.about-list {
    list-style-type: none;
    padding: 0;
    text-align: center;
}

.about-list li {
    margin: 10px 0;
}

.about-list a {
    display: inline-block;
    background-color: #f1c40f;
    color: #2c3e50;
    padding: 10px 20px;
    text-decoration: none;
    font-family: 'Press Start 2P', cursive;
    box-shadow: 4px 4px 0px 0px #000000;
    transition: background-color 0.3s;
}

.about-list a:hover {
    background-color: #e67e22;
}

/* Wallet Input Container */
.wallet-input-container {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-direction: column;
}

/* Wallet Input Styles */
.wallet-input {
    background-color: #e0e0e0;
    color: #a8a8a8;
    font-family: 'Press Start 2P', cursive;
    font-size: 1rem;
    padding: 10px;
    border: 3px solid #000000;
    box-shadow: 4px 4px 0px 0px #000000;
    border-radius: 0;
    width: 300px;
    transition: background-color 0.3s, border-color 0.3s;
}

.wallet-input.error {
    border-color: red;
}

/* Error Message Styles */
.error-message {
    color: red;
    font-size: 12px;
    font-family: 'Press Start 2P', cursive;
    margin-top: 4px;
}

/* Wallet Paste Button */
.paste-button {
    background-color: #f1c40f;
    color: #2c3e50;
    cursor: pointer;
    font-family: 'Press Start 2P', cursive;
    font-size: 1rem;
    padding: 10px 20px;
    text-transform: uppercase;
    border: none;
    box-shadow: 4px 4px 0px 0px #000000;
    transition: background-color 0.3s;
}

.paste-button:hover {
    background-color: #e67e22;
}

/* Connected Button Styles */
.paste-button.connected {
    background-color: #28a745; /* Green color for successful connection */
    color: #ffffff;
}

/* Animation */
@keyframes retroFadeIn {
    0% {
        opacity: 0;
        transform: scale(0.95);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.fadeIn {
    animation: retroFadeIn 1s ease-out forwards;
    opacity: 0;
}
/* Connected Button with Retro Aura Effect */
.paste-button.connected {
    background-color: #28a745; /* Green color for successful connection */
    color: #ffffff;
    position: relative;
    overflow: hidden;
    font-weight: bold;
}

/* Outer Aura for retro pixel glow */
.paste-button.connected::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120%;
    height: 120%;
    background: rgba(40, 167, 69, 0.7); /* Green glow color */
    border-radius: 2px; /* Squared corners for pixelated look */
    transform: translate(-50%, -50%) scale(1);
    animation: retroPulse 1.5s infinite alternate;
    z-index: -1; /* Place the aura behind the button */
    opacity: 0.8;
}

/* Inner Aura for a layered pixel effect */
.paste-button.connected::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background: rgba(40, 167, 69, 0.5);
    border-radius: 2px;
    transform: translate(-50%, -50%) scale(1.1);
    animation: retroPulseInner 1.5s infinite alternate;
    z-index: -2;
    opacity: 0.5;
}

/* Keyframes for the retro pulse and slight flicker effect */
@keyframes retroPulse {
    0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0.8;
    }
    100% {
        transform: translate(-50%, -50%) scale(1.15);
        opacity: 0.6;
    }
}

@keyframes retroPulseInner {
    0% {
        transform: translate(-50%, -50%) scale(1.1);
        opacity: 0.5;
    }
    100% {
        transform: translate(-50%, -50%) scale(1.05);
        opacity: 0.7;
    }
}


.mobile-warning {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    color: #ffdf00;
    background-color: #4ec0ca;
  }
  
  .mobile-warning .title {
    font-size: 2.5rem;
    font-family: 'Press Start 2P', cursive;
    text-shadow: 3px 3px 0px #3a474d;
    margin-bottom: 1rem;
  }
  
  .mobile-warning .description {
    font-family: 'IBM Plex Mono', monospace;
    color: #ffffff;
    font-size: 1.1rem;
    line-height: 1.5;
    max-width: 80%;
  }
  .referral-link-input {
    width: 85%; /* Fits well within the card */
    padding: 10px;
    border: 3px solid #ffcc00; /* Bold, retro-style border */
    border-radius: 3px;
    font-size: 0.9em;
    text-align: center;
    background-color: #2c3e50; /* Darker background for contrast */
    color: #ffdf00; /* Retro yellow text color */
    font-family: 'Press Start 2P', cursive; /* Consistent with site theme */
    box-shadow: 0px 4px 0px #3a474d; /* Retro box shadow */
    margin: 10px auto; /* Center within the card */
  }
  
  .referral-link-input:focus {
    border-color: #e6b800; /* Subtle border color change on focus */
    outline: none;
  }